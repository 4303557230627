<template>
	<el-dialog title="" :visible.sync="createdVisible" width="578px" center :close-on-click-modal="false" @close="onResetForm('ruleForm')">
		<div slot="title" class="postion">
			<i v-if="!isShowDetail" class="iconfont icon-bianji1"></i>
			<span class="download">{{ isShowDetail ? '查看' : !isShowDetail && ruleForm.id ? '编辑' : '新增' }}健康教育</span>
		</div>
		<el-form :model="ruleForm" :rules="isShowDetail ? {} : rules" ref="ruleForm" label-width="100px" class="demo-ruleForm" label-position="top">
			<el-form-item label="活动主题" prop="name">
				<el-input v-model.trim="ruleForm.name" :disabled="isShowDetail" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="活动时间" prop="times">
				<el-date-picker
					v-model="ruleForm.times"
					type="datetimerange"
					range-separator="至"
					start-placeholder="开始时间"
					end-placeholder="结束时间"
					value-format="yyyy-MM-dd HH:mm:ss"
					:disabled="isShowDetail"
				>
				</el-date-picker>
			</el-form-item>
			<el-form-item class="attractionAddress" label="活动地址" prop="address">
				<el-input
					v-model.trim="ruleForm.address"
					disabled
					placeholder="选择地址"
					:style="{ width: isShowDetail ? '100%' : 'calc(100% - 74px)' }"
				></el-input>
				<el-button type="text" @click="chooseAddress" v-if="!isShowDetail">选择地址</el-button>
			</el-form-item>
			<el-form-item label="活动形式" prop="activityForm" class="w50 mr16">
				<el-input v-model.trim="ruleForm.activityForm" :disabled="isShowDetail" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="活动类别" class="w50 mr0" prop="activityTypeDictionaryItemId">
				<el-select
					:disabled="isShowDetail"
					v-model="ruleForm.activityTypeDictionaryItemId"
					placeholder="请选择"
					@change="changeSelect($event, 'activityTypeDictionaryItemId')"
				>
					<el-option v-for="item in activityList" :key="item.id" :label="item.title" :value="item.id"> </el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="组织者" class="w50 mr16" prop="organizerName">
				<el-input v-model.trim="ruleForm.organizerName" :disabled="isShowDetail" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="主讲人" class="w50 mr0" prop="keynoteSpeakerName">
				<el-input v-model.trim="ruleForm.keynoteSpeakerName" :disabled="isShowDetail" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item class="w50 mr16" prop="participants" label="参与人数">
				<el-input v-model.trim="ruleForm.participants" :disabled="isShowDetail" type="number" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item class="w50 mr0" prop="materialNum" label="教育材料数量">
				<el-input v-model.trim="ruleForm.materialNum" :disabled="isShowDetail" type="number" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item class="w50 mr16" prop="reporterName" label="报告人">
				<el-input v-model.trim="ruleForm.reporterName" :disabled="isShowDetail" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item class="w50 mr0" prop="preparerName" label="填表人">
				<el-input v-model.trim="ruleForm.preparerName" :disabled="isShowDetail" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item class="w50 mr16" label="受教育人群" prop="educatedPersonTypeDictionaryItemId">
				<el-select
					:disabled="isShowDetail"
					v-model="ruleForm.educatedPersonTypeDictionaryItemId"
					placeholder="请选择"
					@change="changeSelect($event, 'educatedPersonTypeDictionaryItemId')"
				>
					<el-option v-for="item in educationPeopleList" :key="item.id" :label="item.title" :value="item.id"> </el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="w50 mr0" label="教育材料" prop="materialTypeDictionaryItemId">
				<el-select
					:disabled="isShowDetail"
					v-model="ruleForm.materialTypeDictionaryItemId"
					placeholder="请选择"
					@change="changeSelect($event, 'materialTypeDictionaryItemId')"
				>
					<el-option v-for="item in eduTextList" :key="item.id" :label="item.title" :value="item.id"> </el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="活动内容" prop="description">
				<el-input type="textarea" autosize :disabled="isShowDetail" :rows="2" v-model.trim="ruleForm.description"></el-input>
			</el-form-item>
			<el-form-item label="活动总结评价" prop="summary">
				<el-input type="textarea" autosize :disabled="isShowDetail" :rows="2" v-model.trim="ruleForm.summary"></el-input>
			</el-form-item>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button @click="onResetForm('ruleForm')" type="primary" v-if="isShowDetail">确 定</el-button>
			<el-button v-else type="primary" @click="onSave('ruleForm')" :loading="saveLoading">保 存</el-button>
			<el-button v-if="!isShowDetail" @click="onResetForm('ruleForm')">取 消</el-button>
		</span>
		<choose-address ref="chooseAddress" @changeAddress="changeAddress" />
	</el-dialog>
</template>

<script>
export default {
	components: {
		chooseAddress: () => import('./chooseAddress.vue'),
	},
	data() {
		return {
			createdVisible: false,
			ruleForm: {
				name: '',
				times: [],
				address: '',
				organizerName: '',
				keynoteSpeakerName: '',
				activityForm: '',
				activityTypeDictionaryItemId: '',
				participants: '',
				materialNum: '',
				description: '',
				reporterName: '',
				preparerName: '',
				summary: '',
				educatedPersonTypeDictionaryItemId: '',
				materialTypeDictionaryItemId: '',
				regionCode: '',
			},
			educationPeopleList: [],
			eduTextList: [],
			activityList: [],
			rules: {
				name: [{ required: true, message: '请输入', trigger: 'blur' }],
				organizerName: [{ required: true, message: '请输入', trigger: 'blur' }],
				activityForm: [{ required: true, message: '请输入', trigger: 'blur' }],
				keynoteSpeakerName: [{ required: true, message: '请输入', trigger: 'blur' }],
				applicationDate: [{ required: true, message: '请输入', trigger: 'blur' }],
				materialNum: [{ required: true, message: '请输入', trigger: 'blur' }],
				summary: [{ required: true, message: '请输入', trigger: 'blur' }],
				times: [{ required: true, message: '请选择', trigger: 'change' }],
				description: [{ required: true, message: '请输入', trigger: 'blur' }],
				activityTypeDictionaryItemId: [{ required: true, message: '请选择', trigger: 'change' }],
				address: [{ required: true, message: '请输入详细地址', trigger: 'blur' }],
				participants: [{ required: true, message: '请输入', trigger: 'blur' }],
				reporterName: [{ required: true, message: '请输入', trigger: 'blur' }],
				preparerName: [{ required: true, message: '请输入', trigger: 'blur' }],
				educatedPersonTypeDictionaryItemId: [{ required: true, message: '请选择', trigger: 'change' }],
				materialTypeDictionaryItemId: [{ required: true, message: '请选择', trigger: 'change' }],
			},
			saveLoading: false,
			link: '',
		};
	},
	props: ['isShowDetail'],
	watch: {},
	created() {},
	methods: {
		init({ link, row }) {
			this.createdVisible = true;
			this.link = link;
			this.getEducationPeopleList();
			this.getEduTextList();
			this.getActivityType();
			if (row) {
				this.$nextTick(() => {
					this.$refs.ruleForm.clearValidate();
					this.setRuleForm(row);
				});
				return;
			}
			//新增
			this.ruleForm = this.$options.data().ruleForm; //清空表单
			this.$nextTick(() => {
				this.$refs.ruleForm.clearValidate();
			});
		},
		// 获取受教育人群
		getEducationPeopleList() {
			this.$http
				.get(this.api['DictionaryItems#index'].href, { params: { dictionaryCode: 'EDUCATED_PERSON_TYPE', size: 9999, current: 1 } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.educationPeopleList = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		// 获取教育材料
		getEduTextList() {
			this.$http
				.get(this.api['DictionaryItems#index'].href, { params: { dictionaryCode: 'EDUCATED_MATERIAL_TYPE', size: 9999, current: 1 } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.eduTextList = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		// 获取活动类别
		getActivityType() {
			this.$http
				.get(this.api['DictionaryItems#index'].href, { params: { dictionaryCode: 'HEALTH_ACTIVITY_TYPE', size: 9999, current: 1 } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.activityList = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		// 设置表单数据
		setRuleForm(data) {
			this.ruleForm = {
				...data,
				times: data.startTime && data.endTime ? [data.startTime, data.endTime] : [],
				educatedPersonTypeDictionaryItemId: data.educatedPersonTypeDictionaryItem?.id || '',
				materialTypeDictionaryItemId: data.materialTypeDictionaryItem?.id || '',
				activityTypeDictionaryItemId: data.activityTypeDictionaryItem?.id || '',
			};
		},
		// 选择地址
		chooseAddress() {
			let data = JSON.parse(sessionStorage.getItem('userInfo')) || {};
			if (data.id) {
				this.$refs.chooseAddress.center = [data.longitude || 0, data.latitude || 0];
			}
			this.$refs.chooseAddress.chooseAddressVisible = true;
		},
		// 获取到的地址
		changeAddress(data) {
			const { addressData } = data;
			let townCode = JSON.parse(sessionStorage.getItem('userInfo'))?.chinaRegionDtoList[2]?.code || 0;
			let townTitle = JSON.parse(sessionStorage.getItem('userInfo'))?.chinaRegionDtoList[2]?.title || '';
			if (addressData.adcode != townCode) {
				this.$message.error(`请选择${townTitle}的地址`);
				return;
			}
			this.ruleForm.address = addressData.district + addressData.township + addressData.street + addressData.streetNumber;
			this.ruleForm.longitude = data.lng;
			this.ruleForm.latitude = data.lat;
			this.getTownCode({ code: addressData.adcode, title: addressData.township });
		},
		// 查询乡镇/街道code
		getTownCode({ code, title }) {
			this.$http
				.get(this.api['ChinaRegions#index'].href, { params: { code, title } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.ruleForm.regionCode = res.data.collection[0].code;
					}
				})
				.catch((e) => {});
		},
		// 保存
		onSave(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.saveLoading = true;
					let params = {
						...this.ruleForm,
						startTime: this.ruleForm.times[0] || null,
						endTime: this.ruleForm.times[1] || null,
					};
					let queryApi = this.ruleForm.id ? this.$http.put(this.link, params) : this.$http.post(this.link, params);
					queryApi
						.then((res) => {
							if (res.data && res.data.success) {
								this.$message.success(this.ruleForm.id ? '修改成功' : '添加成功');
								this.$emit('getList');
								this.onResetForm('ruleForm');
								this.saveLoading = false;
							}
						})
						.catch((e) => {
							this.saveLoading = false;
						});
				}else {
					this.$nextTick(() => {
						let isError = document.getElementsByClassName('is-error');
						// var a = $(isError[0]).find("input") //this.$refs.cmd.$el.querySelector('input')
						// a.focus()  光标定位到所在input
						isError[0].scrollIntoView({
							// 滚动到指定节点
							// 值有start,center,end，nearest
							block: 'center',
							// 值有auto、instant,smooth，缓动动画
							behavior: 'smooth',
						});
					});
					return false;
				}
			});
		},
		onResetForm(formName) {
			this.createdVisible = false;
			this.$refs[formName].resetFields();
		},
		changeSelect(val, type) {
			this.ruleForm[type] = val;
			this.$forceUpdate();
		},
	},
};
</script>

<style lang="scss" scoped>
.postion {
	font-size: 18px;
	.icon-bianji1 {
		margin-right: 16px;
		color: #1db9b1;
	}
}
/deep/ .el-form {
	.el-form-item {
		.el-select,
		.el-input,
		.el-date-editor {
			width: 100%;
		}
	}

	.el-form-item__label {
		line-height: 14px;
		height: 14px;
		margin-bottom: 8px;
		padding: 0;
	}
	.addressClass {
		&-content {
			width: calc((100% - 32px) / 3);
			margin-right: 16px;
			margin-bottom: 0;
		}
	}
}
.attractionAddress {
	/deep/ .el-button {
		width: 56px;
		border-bottom: 1px solid #1db9b1;
		margin-left: 16px;
		padding: 0;
		&:hover {
			color: #33a7a1;
			border-bottom: 1px solid #33a7a1;
		}
	}
}
/deep/ .el-button {
	margin-left: 16px;
}
/deep/ .mr0 {
	margin-right: 0 !important;
}
/deep/ .w50 {
	width: calc(50% - 8px);
	display: inline-block;
}
/deep/ .w40 {
	width: calc(50% - 14px);
	display: inline-block;
}
/deep/ .m28 {
	margin-right: 28px;
}
/deep/ .el-textarea__inner {
	min-height: 64px !important;
	line-height: 18px !important;
	padding: 5px 12px !important;
}
</style>